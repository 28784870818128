import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container, Row, Col, Card} from "react-bootstrap"


class Staff extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="About" />
        <Container>
          <Row>
            <Col sm={12} className='mt-5 mb-5'>
            <h1>Capernwray Staff</h1>
              <Card className='mb-5'>
                <Card.Header>
                  <h3>Carol Hack</h3>
                  <h4>Managing Director</h4>
                </Card.Header>
                <Card.Body>
                  <p>
                  Carol comes to the business from a varied background including service as an officer in the RAF followed by several years as a successful financial adviser. Carol controls the purse strings of the business, acts as first point of contact for customers and business inquiries alike, answers the ‘phone, makes sure the bills are paid on time, makes sure the men are getting on with their work (!) and generally ensures the place runs smoothly and efficiently. Carol has also dived extensively, particularly in exotic, tropical locations and is well placed to advise on gear and techniques for this aspect of the sport.
                  </p>
                </Card.Body>
              </Card>
              <Card className='mb-5'>
                <Card.Header>
                  <h3>Chris Collingwood</h3>
                  <h4>Director and Operations Manager</h4>
                </Card.Header>
                <Card.Body>
                  <p>
                  Chris is the ‘face of Capernwray’ and the man responsible for the original development of the site. A hugely experienced diver and ex-RNLI helm, Chris brings his vast wealth of knowledge about diving and boat handling in general to the day to day running of the business. He designed the present in-water layout and personally acquired and arranged the sinking of the wide variety of wrecks and other features in the water. A mine of information, Chris is always on-hand to offer his advice about equipment, techniques or any other aspect of the sport and is more than happy to chat about diving in general and the facilities at Capernwray in detail. An expert technician, Chris also runs his on-site IDEST cylinder testing station ‘Breathe Easy’.
                  </p>
                </Card.Body>
              </Card>
              <Card className='mb-5'>
                <Card.Header>
                  <h3>Kate Hewitson</h3>
                  <h4>Restaurant Manager</h4>
                </Card.Header>
                <Card.Body>
                  <p>
                    Kate runs the Porthole restaurant here at Capernwray. Kate produces delicious food day in and day out, to feed all you hungry divers! She is also excellent at getting the very best deals from our suppliers, and ensuring that the food is not only very tasty, but also great value. Kate is an active diver, and has been diving for about 15 years.
                  </p>
                </Card.Body>
              </Card>
              <Card className='mb-5'>
                <Card.Header>
                  <h3>Guido Quelina</h3>
                </Card.Header>
                <Card.Body>
                  <p>
                  Guido is a PADI Master Instructor,a TecRec Trimix Instructor, a BSAC Advanced Instructor, and DAN and EFR Instructor Trainer. He has been very involved in our local BSAC club, before crossing over to PADI about 14 years ago. He is a key member of staff, and can be found in the shop to advise on equipment, blend gas, and discuss anything diving in knowledgable detail.
                  </p>
                </Card.Body>
              </Card>
              <Card className='mb-5'>
                <Card.Header>
                  <h3>Sandy</h3>
                </Card.Header>
                <Card.Body>
                  <p>
                  Sandy is well known to our regular customers as she provides sustenance to all from behind the bar at weekends. A wonderful barlady, she also dispenses bonhomie with an equal measure of wit and repartee to those waiting patiently in the queue. However, if you don’t wait patiently she will give you a vivid demonstration of her unique grasp of Anglo Saxon prose whilst pulling your pint!!
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default Staff

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
